import React from "react";

const Service1Section: React.FC = () => {
  return (
    <div className="box border-[5px] bg-white border-blue-thin rounded-[36px]">
      <div className="flex items-center flex-col-reverse">
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00] !pt-2 md:!pt-8">
          インターフォンの応答・お断り対応はお任せ！
        </h1>
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00]">
          ボタンひとつで男性の声で代理応答！
        </h1>
      </div>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-12 md:col-span-7">
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 md:!mt-8">
              計198個の音声をご用意！（台詞66種×声の出演者3名）
            </h1>
          </div>
          <div className="p-2 sm:p-4 md:p-8 border-[1px] sm:border-[2px] md:border-[3px] border-[#616161] text-[#616161] mt-4 md:mt-8">
            <div>
              <div className="flex justify-start">
                <h1 className="!text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-xl text-left">
                  （台詞の一例）
                </h1>
              </div>
              <div>
                <h1 className="!text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-xl text-left">
                  はい／ありがとうございます／お願いします／玄関の前に置いておいてください／どちら様ですか／おかえり　など
                </h1>
              </div>
            </div>
          </div>
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 sm:!mt-6 md:!mt-8">
              利用シーンの例
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              ・荷物や出前の受け取りで、女性の家だとばれたくない時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              ・しつこい訪問やセールスの応答で、強めに断りたい時
            </h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-5">
          <img
            className="w-[150px] md:w-full mx-auto"
            src="img/21.png"
            alt=""
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Service1Section;
