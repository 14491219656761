import { Fade, Slide, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";

const IntroSection: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const containerRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    // Delay the first toggle to allow initial animation
    const timeoutId = setTimeout(() => {
      setChecked(true);
    }, 1500); // Time after which the first animation will complete
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const goTo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <section className="main-wrapper">
      <div className="h-[240px] sm:h-[360px] md:h-[400px] lg:h-[500px] bg-green-light content-center">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-7">
            <div className="flex justify-center -mt-4 sm:-mt-8">
              <Slide in={checked} container={containerRef.current}>
                <img
                  src="img/1.png"
                  className="w-[60px] sm:w-[120px] h-[60px] sm:h-[120px]"
                  alt="icon"
                ></img>
              </Slide>
            </div>
            <div className="flex justify-center">
              <Fade in={checked}>
                <div className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                  ＼声で守る、あなたの防犯パートナー／
                </div>
              </Fade>
            </div>
            <div className="flex justify-center mt-4 md:mt-8">
              <Fade in={checked}>
                <img
                  className="w-auto h-[50px] sm:h-[75px] md:h-auto"
                  src="img/28.png"
                  alt="banner"
                ></img>
              </Fade>
            </div>
            <div className="grid grid-cols-12 gap-4 md:gap-8 mt-4 md:mt-8">
              <div className="col-start-3 col-span-4">
                <Zoom in={checked}>
                  <img
                    src="img/2.png"
                    alt="appStore"
                    className="cursor-pointer"
                    onClick={() => goTo("https://www.apple.com/app-store")}
                  ></img>
                </Zoom>
              </div>
              <div className="col-end-11 col-span-4">
                <Zoom
                  in={checked}
                  style={{ transitionDelay: checked ? "500ms" : "0ms" }}
                >
                  <img
                    src="img/3.png"
                    alt="googlePlay"
                    className="cursor-pointer"
                    onClick={() => goTo("https://play.google.com/store/apps")}
                  ></img>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="hidden md:block col-span-0 md:col-span-5">
            <div className="flex justify-center">
              <img
                src="img/iphone1.png"
                alt="iphone1.png"
                className="w-[180px] lg:w-[270px] h-[240px] lg:h-[360px]"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
