import React from "react";
import { Typography } from "@mui/material";
import CommonSection from "../../common/section/CommonSection";
const PlanSection: React.FC = () => {
  return (
    <div className="sm:-mt-4">
      <CommonSection id="plan" title="プランのご紹介">
        <div className="box bg-[#fffde7] !-mx-6 sm:!-mx-8 md:!-mx-16">
          <div className="flex items-center justify-center text-brown-default">
            <div>
              <h1 className="!text-xs sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl tracking-[10px] md:tracking-[14px] !font-bold">{`安⼼プレミアムプランなら、`}</h1>
              <h1 className="!text-xs sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl tracking-[10px] md:tracking-[14px] !font-bold pt-4 md:pt-8">{`すべての機能が使える！`}</h1>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div>
            <table className="w-full">
              <tbody>
                {[
                  [
                    {
                      label: "ご利用プラン",
                      background: "#fff",
                    },
                    {
                      label: "無料版",
                      background: "#bdbdbd",
                    },
                    {
                      label: "安心プレミアムプラン",
                      background: "#fff59d",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            代理応答
                          </span>
                          <br />
                          台詞のパターン
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "限定された台詞の再生が可能",
                      background: "#e1f5fe",
                    },
                    {
                      label: "すべての台詞の再生が可能",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            代理応答
                          </span>
                          <br />
                          声のパターン
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "1種類",
                      background: "#e1f5fe",
                    },
                    {
                      label: "3種類",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            代理応答
                          </span>
                          <br />
                          録音した音声の登録
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "ー",
                      background: "#e1f5fe",
                    },
                    {
                      label: "〇",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#4caf50] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            フェイク通話
                          </span>
                          <br />
                          会話パターン
                        </>
                      ),
                      background: "#fff",
                      borderBottomDashed: true,
                    },
                    {
                      label: "4種類",
                      background: "#e1f5fe",
                      color: "#ef6c00",
                      borderBottomDashed: true,
                    },
                    {
                      label: "12種類",
                      background: "#fffde7",
                      color: "#ef6c00",
                      borderBottomDashed: true,
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#4caf50] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            フェイク通話
                          </span>
                          <br />
                          今すぐ着信
                        </>
                      ),
                      background: "#fff",
                      borderBottomDashed: true,
                    },
                    {
                      label: "〇",
                      background: "#e1f5fe",
                      color: "#ef6c00",
                      borderBottomDashed: true,
                    },
                    {
                      label: "〇",
                      background: "#fffde7",
                      color: "#ef6c00",
                      borderBottomDashed: true,
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#4caf50] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            フェイク通話
                          </span>
                          <br />
                          指定時間に着信音を鳴らす
                        </>
                      ),
                      background: "#fff",
                      borderTopDashed: true,
                    },
                    {
                      label: "ー",
                      background: "#e1f5fe",
                      borderTopDashed: true,
                    },
                    {
                      label: "〇",
                      background: "#fffde7",
                      color: "#ef6c00",
                      borderTopDashed: true,
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            緊急通知
                          </span>
                          <br />
                          友達登録
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "1人まで",
                      background: "#e1f5fe",
                    },
                    {
                      label: "99人まで",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            緊急通知
                          </span>
                          <br />
                          グループ数
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "1グループ",
                      background: "#e1f5fe",
                    },
                    {
                      label: "5グループ",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#4caf50] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            防犯ブザー
                          </span>
                          <br />
                          音のパターン
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "1種類",
                      background: "#e1f5fe",
                    },
                    {
                      label: "9種類",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                  [
                    {
                      label: (
                        <>
                          <span className="text-[#0288d1] text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl">
                            防犯対策
                          </span>
                          <br />
                          メッセージ表示
                        </>
                      ),
                      background: "#fff",
                    },
                    {
                      label: "〇",
                      background: "#e1f5fe",
                      color: "#ef6c00",
                    },
                    {
                      label: "〇",
                      background: "#fffde7",
                      color: "#ef6c00",
                    },
                  ],
                ].map((record, i) => (
                  <tr key={i}>
                    {record.map((_, j) => (
                      <td
                        key={`${i}-${j}`}
                        className="w-[33%] p-2 sm:p-4 md:p-8 border-2 border-gray-600 font-bold text-[10px] sm:text-xs md:text-sm lg:text-lg xl:text-xl content-center"
                        style={{
                          background: _.background,
                          color: _.color,
                          borderLeftStyle: j % 3 ? "dashed" : "solid",
                          borderRightStyle: j % 3 > 1 ? "solid" : "dashed",
                          // borderTopStyle: _.borderTopDashed ? "dashed" : "solid",
                          // borderBottomStyle: _.borderBottomDashed
                          //   ? "dashed"
                          //   : "solid",
                        }}
                      >
                        {_.label}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div
            className="md:hidden tracking-[10px] font-bold text-sm md:text-3xl pt-6 text-left"
            style={{
              color: "#e57373",
            }}
          >
            年間600円お得！
          </div>
          <div className="mt-6 md:mt-16 mb-8 md:mb-20 grid md:grid-cols-2 gap-12 h-auto">
            {[
              {
                plan: "年間プラン",
                planBackground: "#e57373",
                title: "年間600円お得！",
                content: "￥3,000/年",
                buttonLabel: "￥250/月",
              },
              {
                plan: "月額プラン",
                planBackground: "#a5d6a7",
                content: "￥300/月",
              },
            ].map((_, i) => (
              <div key={i} className="flex md:block w-full">
                <div
                  className="relative z-[1] text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-white p-2 sm:p-4 md:p-8 px-6 rounded-2xl mr-[-20px] md:mr-0 md:mb-[-20px] flex items-center justify-center whitespace-nowrap"
                  style={{
                    background: _.planBackground,
                  }}
                >
                  {_.plan}
                </div>
                <div className="w-full grid grid-cols-3 md:grid-cols-1 content-center z-0 bg-white h-full min-h-[60px] border-2 border-gray-600 rounded-xl text-2xl px-[20px] md:px-0 md:pt-[20px]">
                  {!!_.title && (
                    <div className="hidden md:flex justify-center items-center md:py-4">
                      <div
                        className=" tracking-[10px] font-bold text-[10px] sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl border-b-[5px] border-solid"
                        style={{
                          color: _.planBackground,
                          borderColor: _.planBackground,
                        }}
                      >
                        {_.title}
                      </div>
                    </div>
                  )}
                  <div className="col-span-2 md:col-span-1 tracking-[0px] sm:tracking-[8px] !text-xl md:!text-4xl whitespace-nowrap md:py-4 flex items-center justify-center">
                    {_.content}
                  </div>
                  {!!_.buttonLabel && (
                    <div className="flex justify-center items-center md:py-4">
                      <Typography className="!text-[10px] sm:!text-xl md:!text-2xl px-2 sm:px-12 py-1 sm:py-2 rounded-full border-2 border-black whitespace-nowrap">
                        {_.buttonLabel}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div className="pt-6 sm:pt-8 md:pt-10 lg:pt-12">
            <div className="flex justify-around">
              <div className="grid grid-cols-12 gap-6 sm:gap-12">
                <div className="col-span-12 sm:col-span-6">
                  <img src="img/26.png" alt="" className="w-full"></img>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <img src="img/27.png" alt="" className="w-full"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default PlanSection;
