import React from "react";

const Service5Section: React.FC = () => {
  return (
    <div className="h-full box border-[5px] bg-white border-blue-thin rounded-[36px]">
      <div className="flex items-center flex-col-reverse">
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00]">
          こっそり周囲にSOS
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <img
          className="w-[150px] md:w-[300px]"
          src="img/10.png"
          alt="10.png"
        ></img>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              スマホの画面を表示するだけで、声を出せない状況でも
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              こっそり周囲に助けを求めることができます。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              また、被害を受けている方を助ける際にも効果的です。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 sm:!mt-6 md:!mt-8">
              利用シーンの例
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              ・痴漢被害にあった時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              ・被害を受けている方を助けたい時
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service5Section;
