import React from "react";

const Footer = () => {
  return (
    <footer className="main-wrapper">
      <div className="h-auto bg-[#fff] p-4 content-center">
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-8 content-center">
            <h1 className="tracking-[1px] sm:tracking-[4px] !text-sm md:!text-lg lg:!text-xl xl:!text-2xl text-center sm:text-right text-[#616161]">
              <a
                href="https://koemamo.com/policy/privacy.pdf"
                target="_blank"
                className="cursor-pointer"
              >
                プライバシーポリシー　|　
              </a>
              <a
                href="https://altrobo.co.jp"
                target="_blank"
                className="cursor-pointer"
              >
                運営会社
              </a>
            </h1>
          </div>
          <div className="col-span-12 sm:col-span-4 flex items-start justify-center sm:justify-end">
            <a
              href="https://altrobo.co.jp"
              target="_blank"
              className="cursor-pointer"
            >
              <img
                className="w-[100px] md:w-[200px] h-auto"
                src="img/19.png"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
