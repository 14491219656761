import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { DehazeOutlined } from "@mui/icons-material";
import "./style.css";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      id="header"
      className="main-wrapper bg-[#fff] flex items-center justify-between gap-6 px-2 md:px-8 py-2 md:py-4"
    >
      <div className="hidden md:flex md:gap-6">
        {[
          {
            href: "#koemamo",
            label: "コエマモとは",
          },
          {
            href: "#service",
            label: "サービスの特徴",
          },
          {
            href: "#plan",
            label: "プランのご紹介",
          },
          {
            href: "#comment",
            label: "よくあるご質問",
          },
        ].map((_, i) => (
          <a
            key={i}
            href={_.href}
            className="text-xs md:text-sm lg:text-xl xl:text-2xl font-bold border-b-8 py-2 text-gray-500 hover:bg-gray-100 border-blue-light"
          >
            {_.label}
          </a>
        ))}
      </div>
      <div className="block md:hidden">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <DehazeOutlined />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <a href="#koemamo">コエマモとは</a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#service">サービスの特徴</a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#plan">プランのご紹介</a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a href="#comment">よくあるご質問</a>
          </MenuItem>
        </Menu>
      </div>
      <div>
        <Button
          href="#question"
          variant="contained"
          className="!rounded-xl w-[160px] md:w-auto"
        >
          <div className="block content-center">
            <EmailOutlinedIcon className="!w-3 md:!w-auto mr-2 md:mb-0.5 lg:mb-1" />
            <span className="text-[10px] md:text-sm lg:text-lg xl:text-xl">
              ご意⾒・お問い合わせ
            </span>
          </div>
        </Button>
      </div>
    </header>
  );
};

export default Header;
